const guide = {
    start_building: 'Inizia a costruire',
    get_started: 'Comincia',
    categories: {
        featured: 'Popolare e per te',
        Traditional: 'Applicazione Web Tradizionale',
        SPA: 'App a pagina singola',
        Native: 'Nativo',
        MachineToMachine: 'Dalla macchina alla macchina',
        Protected: 'Integrazione non-SDK',
        ThirdParty: 'App di terze parti',
    },
    filter: {
        title: 'Filtra framework',
        placeholder: 'Cerca framework',
    },
    checkout_tutorial: 'Vedi il tutorial di {{name}}',
    do_not_need_tutorial: 'Se non hai bisogno di un tutorial, puoi continuare senza una guida al framework',
    finish_and_done: 'Termina e fine',
    cannot_find_guide: 'Non riesci a trovare la tua guida?',
    describe_guide_looking_for: 'Descrivi la guida che stai cercando',
    request_guide_successfully: 'Il tuo richiesta è stata inviata con successo. Grazie!',
    app: {
        select_framework_or_tutorial: 'Seleziona un framework o tutorial',
        guide_modal_title: "Inizia con l'SDK e le guide",
        modal_subtitle: "Sblocca il tuo processo di sviluppo dell'app con il nostro SDK e tutorial predefiniti.",
        select_a_framework: 'Seleziona un framework',
        continue_without_framework: "Crea un'app senza framework",
        describe_guide_looking_for_placeholder: 'Esempio: Voglio integrare Seitrace nella mia app Angular.',
    },
    api: {
        modal_title: 'Inizia con i tutorial',
        modal_subtitle: "Sblocca il tuo processo di sviluppo dell'app con i nostri tutorial predefiniti.",
        select_a_tutorial: 'Seleziona un tutorial',
        continue_without_tutorial: 'Continua senza tutorial',
        describe_guide_looking_for_placeholder: 'Esempio: Voglio proteggere la mia API usando deno.',
    },
};
export default Object.freeze(guide);
