const get_started = {
    page_title: 'Inizia',
    title: 'Esplora per avere successo',
    subtitle: 'Alcune cose che puoi fare per ottenere rapidamente il valore di Seitrace',
    develop: {
        title: 'Sviluppa: Dedica 5 minuti per integrare la tua app',
        title_cloud: 'Sviluppa: Integra in modo sicuro la tua app in pochi minuti',
        subtitle_cloud: 'Oppure impiega 5 minuti per integrare la tua app con i nostri SDK predefiniti e i tutorial.',
    },
    customize: {
        title: "Personalizza: Offri un'ottima esperienza di accesso",
        preview: {
            title: "Controlla l'anteprima in tempo reale dell'esperienza di accesso appena personalizzata",
            subtitle: "Prova subito l'esperienza di accesso a Seitrace per vedere come funziona",
        },
        connector: {
            title: 'Aggiungi più connettori per supportare più metodi di accesso sociali',
            subtitle: "Prova l'accesso senza password e abilita un'esperienza sicura e senza attriti per i tuoi clienti",
        },
        continue_customizing: 'Continua la personalizzazione',
        try_now: 'Prova ora',
        add_more: 'Aggiungi altro',
    },
    secure: {
        title: 'Sicurezza: Proteggi le tue risorse',
    },
    manage: {
        title: 'Gestisci: Definisci il controllo di accesso per il tuo prodotto e gli utenti',
        rbac: {
            title: 'Aggiungi il controllo degli accessi basato sui ruoli per proteggere le tue risorse',
            subtitle: "Controlla le tue risorse attraverso l'autorizzazione basata sui ruoli scalabile per casi d'uso diversi.",
        },
        create_roles: 'Crea ruoli',
    },
    view_all: 'Visualizza tutto →',
};
export default Object.freeze(get_started);
